import LocomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import each from "lodash/each";
import { copyText } from "./utils/index";
// import Home from "./pages/home";

const toContactButtons = document.querySelectorAll(".contact-scroll");
const footer = document.getElementById("js-footer");
const scrollEl = document.querySelector("[data-scroll-container]");
const emailButton = document.querySelector("button.email");
const toCopyText = document.querySelector(".to-copy span");
// const body = document.body;

gsap.registerPlugin(ScrollTrigger);

const scroll = new LocomotiveScroll({
  el: scrollEl,
  smooth: true,
  lerp: 0.06,
  tablet: {
    breakpoint: 768,
  },
});

setTimeout(() => {
  scroll.update();
}, 1000);

scroll.on("scroll", ScrollTrigger.update);


ScrollTrigger.scrollerProxy(scroll.el, {
  scrollTop(value) {
    return arguments.length
      ? scroll.scrollTo(value, 0, 0)
      : scroll.scroll.instance.scroll.y;
  },

  getBoundingClientRect() {
    return {
      top: 0,
      left: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
});

export default class Home {
  constructor(scroll) {
    this.locomotive = scroll;
    this.heroTextAnimation();
    this.homeIntro();
    this.homeAnimations();
    this.homeActions();
  }

  homeActions() {
    each(toContactButtons, (button) => {
      button.onclick = () => {
        this.locomotive.scrollTo(footer);
      };
    });

    emailButton.addEventListener("click", (e) => {
      copyText(e);
      toCopyText.textContent = "copied";

      setTimeout(() => {
        toCopyText.textContent = "Click To Copy";
      }, 2000);
    });
  }

  homeIntro() {
    const tl = gsap.timeline();

    gsap.to(scrollEl, {
      autoAlpha: 1,
    });

    tl.from(".home__nav", {
      duration: 0.5,
      delay: 0.3,
      opacity: 0,
      yPercent: -100,
      ease: "power4.out",
    })
      .from(".hero__title [title-overflow]", {
        duration: 0.7,
        yPercent: 100,
        stagger: {
          amount: 0.2,
        },
        ease: "power4.out",
      })
      .from(
        ".hero__title .bottom__right",
        {
          duration: 1,
          yPercent: 100,
          opacity: 0,
          ease: "power4.out",
        },
        "<20%"
      )
      .set(".hero__title .overflow", { overflow: "unset" })
      .from(
        ".hero__title .mobile",
        {
          duration: 0.7,
          yPercent: 100,
          stagger: {
            amount: 0.2,
          },
          ease: "power4.out",
        },
        "-=1.4"
      );
  }

  homeAnimations() {
    gsap.to(".home__projects__line", { autoAlpha: 1 });
    gsap.utils.toArray(".home__projects__line").forEach((el) => {
      const line = el.querySelector("span");
      gsap.from(line, {
        duration: 1.5,
        scrollTrigger: {
          trigger: el,
          scroller: "[data-scroll-container]",
        },
        scaleX: 0,
      });
    });

    gsap.utils.toArray("[data-fade-in]").forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          scroller: "[data-scroll-container]",
        },
        duration: 1.5,
        yPercent: 100,
        opacity: 0,
        ease: "power4.out",
      });
    });

    if (window.innerWidth <= 768) {
      gsap.utils.toArray(".home__projects__project").forEach((el) => {
        const text = el.querySelector(".title__main");
        const link = el.querySelector(".project__link");
        gsap.from([text, link], {
          scrollTrigger: {
            trigger: el,
            scroller: "[data-scroll-container]",
          },
          duration: 1.5,
          yPercent: 100,
          stagger: {
            amount: 0.2,
          },
          ease: "power4.out",
        });
      });

      const awardsTl = gsap.timeline({
        defaults: {
          ease: "power1.out",
        },
        scrollTrigger: {
          trigger: ".home__awards",
          scroller: "[data-scroll-container]",
        },
      });
      awardsTl.from(".awards-title span", {
        duration: 1,
        opacity: 0,
        yPercent: 100,
        stagger: {
          amount: 0.2,
        },
      });
    }
  }

  heroTextAnimation() {
    gsap.to(".hero__title__dash.desktop", {
      scrollTrigger: {
        trigger: ".hero__title",
        scroller: "[data-scroll-container]",
        scrub: true,
        start: "-8% 9%",
        end: "110% 20%",
      },
      scaleX: 4,
      ease: "none",
    });
  }
}

new Home(scroll);


// ------------------------------- scroll to top js ------------------------------------------------
let mybutton = document.querySelector(".scroll_to_top");
scroll.on("scroll", function() {scrollFunction()});
function scrollFunction() {
  if (scroll.scroll.instance.scroll.y > 4500) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  scroll.scrollTo("#top", 0, 0);
}

mybutton?.addEventListener("click", topFunction);


// ------------------------------- reveal image on hover js ------------------------------------------------
// const projects = document.querySelector( ".projects" );
// const preview = document.querySelector( ".preview" );
// const previewImg = document.querySelector( ".preview-img" );
//
// let isInside = false;
//
// const bgPositions = {
//   p1: "0 0",
//   p2: "0 25%",
//   p3: "0 50%",
//   p4: "0 75%",
//   p5: "0 100%",
// };
//
// const imageSrcMap = {
//   p1: "https://images.unsplash.com/photo-1485470733090-0aae1788d5af?auto=format&fit=crop&q=80&w=3317&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//   p2: "https://images.unsplash.com/photo-1485470733090-0aae1788d5af?auto=format&fit=crop&q=80&w=3317&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
// };
//
// const moveStuff = ( e ) => {
//   const mouseInside = isMouseInsideContainer( e );
//   console.log( mouseInside );
//
//   if(mouseInside !== isInside) {
//     isInside = mouseInside;
//     console.log(isInside)
//     if (isInside) {
//       gsap.to(preview, {
//         duration: 0.3,
//         scale: 1,
//       });
//     } else {
//       gsap.to(preview, {
//         duration: 0.3,
//         scale: 0,
//       });
//     }
//   }
// };
//
// const moveProject = ( e ) => {
//   console.log(e.pageX, e.pageY)
//   const previewRect = preview.getBoundingClientRect();
//
//   const offsetX = previewRect.width / 2;
//   const offsetY = previewRect.height / 2;
//    
//   preview.style.left = e.pageX - offsetX + "px";
//   preview.style.top = e.pageY - offsetY + "px";
// };
//
// const moveProjectsImg = ( project ) => {
//   const projectId = project.id;
//   console.log( projectId )
//   let lastImageSrc = previewImg.style.backgroundImage;
//   let imageSrc = imageSrcMap[ projectId ]; 
//   if ( imageSrc != lastImageSrc) {
//     previewImg.style.backgroundImage = `url('${imageSrc}')`;
//     lastImageSrc = imageSrc;
//   }
//
//   gsap.to( previewImg, {
//     backgroundPosition: bgPositions[ projectId ] || "0 0",
//     scale: 0.4,
//     backgroundImage: `url('${imageSrc}')`,
//     height: "100%",
//     width: "100%",
//     onComplete: () => {
//       console.log("image loaded") 
//     },
//   } );
// };
//
// const isMouseInsideContainer = ( e ) => {
//   const containerRect = projects.getBoundingClientRect();
//   return (
//     e.pageX > containerRect.left &&
//     e.pageX < containerRect.right &&
//     e.pageY > containerRect.top &&
//     e.pageY < containerRect.bottom
//   )
// };
//
// window.addEventListener( "mousemove", moveStuff );
//
// Array.from(projects.children).forEach( project => {
//   project.addEventListener( "mousemove", moveProject );
//   project.addEventListener( "mousemove", moveProjectsImg.bind(null, project));
// });
//
// -------------------------- get current pune time js -----------------------------------------
//
function getCurrentTimeIST() {
    var utcDate = new Date();
    var istOffset = 5.5 * 60 * 60 * 1000;
    var istDate = new Date(utcDate.getTime() + istOffset);

    var hours = istDate.getUTCHours().toString().padStart(2, '0');
    var minutes = istDate.getUTCMinutes().toString().padStart(2, '0');

    return hours + ':' + minutes;
}

function updateCurrentTime() {
    var currentTimeIST = getCurrentTimeIST();
    document.getElementById('currentTime').textContent = currentTimeIST;
}

setInterval(updateCurrentTime, 1000);

// Initial update
updateCurrentTime();
